import $ from "jquery";

// LOCALLY USED FUNCTION
// dismiss alert
function dismissAlert() {
  setTimeout(() => {
    if ($(".alert").hasClass("show")) {
      $(".alert").removeClass("show");
      $(".alert").addClass("d-none");
    }
  }, 5000);
}

// EXPORTABLE FUNCTION
// showError alert
export const showError = (message) => {
  if ($(".alert").hasClass("d-none")) {
    $(".alert").removeClass("d-none");
  }

  $(".alert").addClass("alert-danger");
  $(".alert-badge").text("Error:");
  $(".alert-message").text(message);
  $(".alert").addClass("show");

  dismissAlert();
  showHideLoader("HIDE");
};

// show successAlert
export const showSuccess = (message) => {
  if ($(".alert").hasClass("d-none")) {
    $(".alert").removeClass("d-none");
  }

  $(".alert").addClass("alert-success");
  $(".alert-badge").text("Success:");
  $(".alert-message").text(message);
  $(".alert").addClass("show");

  dismissAlert();
  showHideLoader("HIDE");
};

/**
 * Show/ Hide Loader.
 * @params type = 'SHOW', 'HIDE'
 *
 */
export const showHideLoader = (type) => {
  if (type === "SHOW") {
    $("#loaderMain").show();
  } else {
    $("#loaderMain").hide();
  }
};
