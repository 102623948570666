import { Link } from "react-router-dom";

export default function Info() {
  return (
    <section className="info_section layout_padding-top layout_padding2-bottom">
      <div className="container">
        <div className="box">
          <div className="info_form">
            <h4>Subscribe Our Newsletter</h4>
            <form action="">
              <input type="text" placeholder="Enter your email" />
              <div className="d-flex justify-content-end">
                <button></button> {/* on newsletter subscribe button */}
              </div>
            </form>
          </div>
          <div className="info_links">
            <ul>
              <li className=" ">
                <Link to="">Home</Link>
              </li>
              <li className="">
                <Link to="/about">About Us</Link>
              </li>
              <li className="">
                <Link to="/services">Services</Link>
              </li>
              <li className="">
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="info_social">
            <div>
              <Link>
                <img src="/images/fb.png" alt="" />
              </Link>
            </div>
            <div>
              <Link>
                <img src="/images/twitter.png" alt="" />
              </Link>
            </div>
            <div>
              <Link>
                <img src="/images/linkedin.png" alt="" />
              </Link>
            </div>
            <div>
              <Link>
                <img src="/images/instagram.png" alt="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
