import { useState } from "react";
import Layout from "../components/Layout";
import Alert from "../components/alert";
import { resetForm, showError, showHideLoader, showSuccess } from "../common/common";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // Send email and message data to your server
  const handleSubmit = (e) => {
    e.preventDefault();
    showHideLoader("SHOW");
    fetch("http://localhost:3001/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, phone, message }),
    })
      .then((response) => {
        if (response.ok) {
          showSuccess("Email sent successfully");
          resetFormAndClearStates("contactForm");
        } else {
          showError("Failed to send email");
        }
      })
      .catch((error) => {
        showError(error);
      });
  };

  // Reset form and clear states
  const resetFormAndClearStates = (id) => {
    setEmail("");
    setPhone("");
    setMessage("");
    document.getElementById(id).reset();
  };

  return (
    <Layout showBanner={false}>
      <section className="contact_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>
              Any Problems <br />
              Any Questions
            </h2>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-5  offset-md-1">
              <div className="contact_form">
                <Alert />
                <h4>Get In touch</h4>
                <form id="contactForm" onSubmit={handleSubmit}>
                  <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your Email" required />
                  <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Your Phone Number" required />
                  <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Your message" required className="message_input" />
                  <button type="submit">Send</button>
                </form>
              </div>
            </div>
            <div className="col-md-6 px-0">
              <div className="img-box">
                <img src="images/contact-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
