import { Link } from "react-router-dom";
import Layout from "../components/Layout";

export default function Services() {
  return (
    <Layout showBanner={false}>
      <section className="service_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>
              Our <br />
              Taxi Services
            </h2>
          </div>
          <div className="service_container">
            <div className="box">
              <div className="img-box">
                <img src="images/delivery-man.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>Private Driver</h5>
                <p>Lorem ipsum dolor sit ame</p>
                <Link>Read More</Link>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="images/airplane.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>Airport Transfer</h5>
                <p>Lorem ipsum dolor sit ame</p>
                <Link>Read More</Link>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="images/backpack.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>Luggage Transfer</h5>
                <p>Lorem ipsum dolor sit ame</p>
                <Link>Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
