export default function Footer() {
  let year = new Date().getFullYear();
  return (
    <section className="container-fluid footer_section">
      <div className="container">
        <p>
          &copy; {year} All Rights Reserved &nbsp;
          <b>
            Powered By &nbsp;<a href="https://debug.rawknowledge.org/">Debug Solutions</a>
          </b>
        </p>
      </div>
    </section>
  );
}
