// import Clients from "../components/Clients";
import Layout from "../components/Layout";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <Layout>
      <section className="about_section layout_padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1">
              <div className="detail-box">
                <h2>
                  About <br />
                  Taxi Company
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamm</p>
                <Link>Read More</Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src="images/about-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>
              Our <br />
              Taxi Services
            </h2>
          </div>
          <div className="service_container">
            <div className="box">
              <div className="img-box">
                <img src="images/delivery-man.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>Private Driver</h5>
                <p>Lorem ipsum dolor sit ame</p>
                <Link>Read More</Link>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="images/airplane.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>Airport Transfer</h5>
                <p>Lorem ipsum dolor sit ame</p>
                <Link>Read More</Link>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="images/backpack.png" alt="" />
              </div>
              <div className="detail-box">
                <h5>Luggage Transfer</h5>
                <p>Lorem ipsum dolor sit ame</p>
                <Link>Read More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Clients /> */}

      <section className="why_section layout_padding">
        <div className="container">
          <div className="heading_container">
            <h2>
              Why <br />
              Choose Us
            </h2>
          </div>
          <div className="why_container">
            <div className="box">
              <div className="img-box">
                <img src="images/delivery-man-white.png" alt="" className="img-1" />
                <img src="images/delivery-man-black.png" alt="" className="img-2" />
              </div>
              <div className="detail-box">
                <h5>Best Drivers</h5>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its</p>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="images/shield-white.png" alt="" className="img-1" />
                <img src="images/shield-black.png" alt="" className="img-2" />
              </div>
              <div className="detail-box">
                <h5>Safe and Secure</h5>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its</p>
              </div>
            </div>
            <div className="box">
              <div className="img-box">
                <img src="images/repairing-service-white.png" alt="" className="img-1" />
                <img src="images/repairing-service-black.png" alt="" className="img-2" />
              </div>
              <div className="detail-box">
                <h5>24x7 support</h5>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
