import "./App.css";
import MainRoutes from "./Routes";
import Loader from "./components/Loader";

function App() {
  return (
    <>
      <Loader />
      <MainRoutes />
    </>
  );
}

export default App;
