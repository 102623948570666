import "../assets/css/loader.css";

export default function Loader() {
  return (
    <div id="loaderMain" style={{ display: "none" }}>
      <div id="loaderOverlay"></div>
      <div id="loader"></div>
    </div>
  );
}
