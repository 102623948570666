import "../assets/css/bootstrap.css";
import "../assets/css/style.css";
import "../assets/css/responsive.css";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Info from "./Info";

export default function Layout({ children, showBanner }) {
  return (
    <section>
      <Header showBanner={showBanner !== undefined ? showBanner : true} />
      {children}
      <Info />
      <Footer />
    </section>
  );
}
