import Layout from "../components/Layout";

export default function About() {
  return (
    <Layout showBanner={false}>
      <section className="about_section layout_padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-md-5 offset-lg-2 offset-md-1">
              <div className="detail-box">
                <h2>
                  About <br />
                  Taxi Company
                </h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniaLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniamm</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="img-box">
                <img src="images/about-img.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
